<template>
  <div v-if="crumbs" class="breadcrumbs container">
    <nuxt-link to="/" class="home-breadcrumb">
      <BaseIcon icon="home"></BaseIcon>
    </nuxt-link>
    <BreadcrumbsItem
      v-for="breadcrumb in crumbs"
      :key="breadcrumb.to"
      :path="localePath(breadcrumb.to)"
    >
      {{ breadcrumb.text }}
    </BreadcrumbsItem>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n();
const localePath = useLocalePath();

type Breadcrumb = {
  to: string;
  text?: string;
};

const props = defineProps<{ breadcrumbs?: Breadcrumb[] }>();

const crumbs: Ref<Breadcrumb[] | null> = ref(null);

if (!props.breadcrumbs) {
  // If there is no crumb given, we'll use the current route and fetch a title from the translations
  const route = useRoute();
  const router = useRouter();
  const getRouteBaseName = useRouteBaseName();
  const routeName = getRouteBaseName(router.resolve(route.fullPath));

  if (routeName) {
    crumbs.value = [
      {
        to: routeName,
        text: t(`${routeName}.title`),
      },
    ];
  }
} else {
  // If there is a crumb given, we'll use that and (if no text given) fetch a title from the translations
  const convertedItems = props.breadcrumbs.map((item) => {
    return {
      to: item.to,
      text: item.text ?? t(`${item.to}.title`),
    };
  });
  crumbs.value = convertedItems;
}
</script>

<style lang="scss" scoped>
.breadcrumbs {
  display: flex;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;

  .home-breadcrumb {
    line-height: 1;
    color: $dark-100;
  }
}
</style>
